.site-social {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.site-social ul {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.site-social ul li {
  width: 110px;
}

.site-social ul li {
  vertical-align: middle;
  text-align: center;
}

.site-social ul li > a {
  color: #636a82;
  width: 150px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.site-social ul li > a:hover {
  color: #008c68;
}

.site-social span.icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.site-social span.icon.linkedin {
  background-image: url("../../../public/images/linkedin-round-line-icon.svg");
}

.site-social span.icon.github {
  background-image: url("../../../public/images/github-icon.svg");
}
