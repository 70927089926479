.site {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.site-section {
  margin-bottom: 40px;
}

.site-section h2 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #e5ebf2;
  color: #009f68;
  padding-bottom: 30px;
}

.site-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:hover {
  color: #009f68;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0 auto;
  list-style: none;
}

ul li {
  list-style: none;
}
